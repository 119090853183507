document.addEventListener('DOMContentLoaded', function() {

  console.log('Ready javascript');

  // Mobile > all touchstar DOM - Iphone
  // document.addEventListener("touchstart", function(){}, true);



  document.body.addEventListener('click', function(e) {
    if (e.target.tagName === 'A') {
      var link = e.target;
      var hostname = window.location.hostname;

      // Verificar si el enlace usa "javascript:"
      if (link.href.startsWith('javascript:')) {
        return; // No hacer nada y permitir que se ejecute el código JavaScript
      }

      // Verifica si es un enlace externo y no tiene el atributo target="_blank"
      if (link.hostname !== hostname && link.target !== '_blank') {
        e.preventDefault(); // Previene la acción por defecto
        window.open(link.href, '_blank'); // Abre el enlace en una nueva ventana/pestaña
      }
    }
  });




  // Componente FAQs
  // https://misaluddigestiva.dev-rubio.com/wp-content/themes/digestivo/src/js/modules/scripts.js
  var articles = document.querySelectorAll('.js-EventClick__c-faqs__article');
  articles.forEach(function(article) {
    article.addEventListener('click', function() {
      this.classList.toggle('is-active');
    });

    var linksInsideArticle = article.querySelectorAll('a');
    linksInsideArticle.forEach(function(link) {
      link.addEventListener('click', function(event) {
        event.stopPropagation();
        event.preventDefault(); 
        window.open(link.href, '_blank'); 
      });
    });
  });






  // scrollTop link anchors
  $("a").on('click', function(event) {
    var hash = this.hash;
    var targetElement = $(hash);
    
    // exclude class fancybox
    if (hash !== "" && !$(this).hasClass('fancybox') && targetElement.length) {
      event.preventDefault();

      var topPage = targetElement.offset().top;
      $('html, body').animate({
        scrollTop: topPage
      }, 800, function(){
        window.location.hash = hash;
      });
    }
  });





// When the user scrolls the page, execute myFunction
window.onscroll = function() { myFunction(); };

// Get the header
var body = document.getElementById("body");

// Get the offset position of the navbar
var sticky = body.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  //if (window.pageYOffset > sticky && window.innerWidth > 1120) {
    if (window.pageYOffset > sticky) {
    // document.getElementById("main").style.paddingTop = header.clientHeight + ".px";
    body.classList.add("is-active-scroll");
  } else {
    // document.getElementById("main").style.paddingTop = 0;
    body.classList.remove("is-active-scroll");
  }
}


$('#js-header__menu-mobile').click(function(){
  $('#body').toggleClass('is-active-menu');
  $(this).toggleClass('is-active');
  $('.header__layer').toggleClass('is-active');
});

$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});

$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});


});




