// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('material-scrolltop');
$('body').materialScrollTop();


require('@fancyapps/fancybox');

// Scripts
require('./modules/scripts');

document.addEventListener('DOMContentLoaded', function () {

 var AOS = require('aos');
  // https://michalsnik.github.io/aos/
  AOS.init({duration:800,easing:'slide',once:true});


});



	









